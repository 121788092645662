import store from '@/store'

export const tableOption = {
  menuAlign: 'left',
  editBtn: false,
  delBtn: false,
  topPage: false,
  searchSize: 'small',
  border: true,
  // align: 'center',
  column: [
    {
      label: 'LOGO',
      prop: 'logo',
      span: 18,
      slot: true
    },
    {
      label: '平台编码',
      prop: 'code',
      search: false,
      span: 18
    },
    {
      label: '平台名称',
      prop: 'name',
      search: true,
      span: 18
    }
    // {
    //   label: '是否支持订单导入',
    //   prop: 'isImportWaybill',
    //   slot: true,
    //   span: 18
    // },
    // {
    //   label: '是否支持API同步',
    //   prop: 'isDockApi',
    //   slot: true,
    //   span: 18
    // }
  ]
}
